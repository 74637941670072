import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/mark/di-net2/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Prepostness`}</h1>
    <h4>{`June 20-26, 2021 | Vinalhaven, ME`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px",
          "margin": "5vh 0",
          "objectFit": "contain"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAf/EABUBAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAGXUpirxIf/xAAbEAACAgMBAAAAAAAAAAAAAAABAhEiAAMUQv/aAAgBAQABBQI6Kct2hT5Dxiwo/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGxAAAgMAAwAAAAAAAAAAAAAAAREAAhIQIUH/2gAIAQEABj8CevHFa/USqZnRhDY4/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARIUFRYf/aAAgBAQABPyFCMKVGLamaL7UEOnFegy/kVBez/9oADAMBAAIAAwAAABAP3//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPxCtf//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/ECf/xAAcEAEBAAIDAQEAAAAAAAAAAAABEQAhMUFhcVH/2gAIAQEAAT8QIl2ITm+OGJGw0d/fcZIkRlpx18xVDMTkn5idTqVr2MeXtOh7z//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Prepostness",
          "title": "Prepostness",
          "src": "/static/399eb10ff4a9d15fd4c15991a24bc357/e5166/IMG_4856.jpg",
          "srcSet": ["/static/399eb10ff4a9d15fd4c15991a24bc357/f93b5/IMG_4856.jpg 300w", "/static/399eb10ff4a9d15fd4c15991a24bc357/b4294/IMG_4856.jpg 600w", "/static/399eb10ff4a9d15fd4c15991a24bc357/e5166/IMG_4856.jpg 1200w", "/static/399eb10ff4a9d15fd4c15991a24bc357/d9c39/IMG_4856.jpg 1800w", "/static/399eb10ff4a9d15fd4c15991a24bc357/df51d/IMG_4856.jpg 2400w", "/static/399eb10ff4a9d15fd4c15991a24bc357/d2602/IMG_4856.jpg 4032w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p><strong parentName="p">{`We find ourselves in a pause between how things used to be and the unknown days ahead. The world is captive to a strange isolation while at the same time building unprecedented histories. The ordinary gulf between quarantine and community collapses. We are all alone, together.`}</strong></p>
    <p><strong parentName="p">{`Everyone wants to know how soon, how fast, for who, to whom, how many, how much. In the midst of massive revisions—for justice, for health, for reform—DesignInquiry wonders where gentle, considered, slow, and small moves can be found.`}</strong></p>
    <p><strong parentName="p">{`Prepostness embraces this moment of distortion, collision and pause for working together and apart, so we can give shape and meaning to our collective existential dread.`}</strong></p>
    <p><strong parentName="p">{`“You must go on. I can't go on. I'll go on.”`}</strong>{` —Samuel Beckett`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      